var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-start space-x-6"},[(_vm.isAuthenticated && !_vm.isShortlistPage)?_c('aside',{staticClass:"\n        border border-grey-500\n        rounded\n        max-w-xs\n        w-full\n        divide-y divide-grey-500\n      "},[(
          _vm.showPublishers &&
          _vm.filterParams.publishers &&
          _vm.filterParams.publishers.length > 0
        )?_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Publishers ")])]),_c('template',{slot:'content'},[_c('checkbox-field',{attrs:{"name":"publishers","options":_vm.filterParams.publishers,"value":_vm.filters.publishers},on:{"input":function (event) {
                _vm.handleFiltersChange('publishers', event);
              }}})],1)],2):_vm._e(),(_vm.filterParams.imprints && _vm.filterParams.imprints.length > 0)?_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Imprints ")])]),_c('template',{slot:'content'},[_c('checkbox-field',{attrs:{"name":"imprints","options":_vm.filterParams.imprints,"value":_vm.filters.imprints},on:{"input":function (event) {
                _vm.handleFiltersChange('imprints', event);
              }}})],1)],2):_vm._e(),(_vm.filterParams.subjects && _vm.filterParams.subjects.length > 0)?_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Subjects ")])]),_c('template',{slot:'content'},[_c('checkbox-field',{attrs:{"name":"subjects","options":_vm.filterParams.subjects,"value":_vm.filters.subjects},on:{"input":function (event) {
                _vm.handleFiltersChange('subjects', event);
              }}})],1)],2):_vm._e(),_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Publication Date ")])]),_c('template',{slot:'content'},[_c('t-datepicker',{attrs:{"monthsPerView":2,"multiple":true,"range":true,"weekStart":1,"placeholder":"Choose date or period","dateFormat":"Y-m-d","userFormat":"d M Y","value":_vm.filters.publishingDate},on:{"input":function (event) {
                if (event && (event.length == 0 || event.length == 2)) {
                  _vm.handleFiltersChange('publishingDate', event);
                }
              }}})],1)],2),_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Price ")])]),_c('template',{slot:'content'},[_c('div',{staticClass:"\n              flex\n              divide-x divide-grey-500\n              border border-grey-500\n              rounded\n              overflow-hidden\n              mb-5\n            "},[_c('div',{staticClass:"flex items-center px-3 py-2"},[_c('label',{staticClass:"text-base text-black-900 mr-2",attrs:{"for":"rangeInputMin"}},[_vm._v(" SGD ")]),_c('input',{staticClass:"\n                  w-full\n                  text-base text-black-900\n                  placeholder:text-grey-700\n                  hide-arrows\n                ",attrs:{"id":"rangeInputMin","type":"number","placeholder":"From","min":0},domProps:{"value":_vm.filters.priceRange.length > 0 ? _vm.filters.priceRange[0] : 0},on:{"blur":_vm.checkMinRangeInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkMinRangeInput.apply(null, arguments)}}})]),_c('div',{staticClass:"flex items-center px-3 py-2"},[_c('label',{staticClass:"text-base text-black-900 mr-2",attrs:{"for":"rangeInputMax"}},[_vm._v(" SGD ")]),_c('input',{staticClass:"\n                  w-full\n                  text-base text-black-900\n                  placeholder:text-grey-700\n                  hide-arrows\n                ",attrs:{"id":"rangeInputMax","type":"number","placeholder":"To"},domProps:{"value":_vm.filters.priceRange.length > 0
                    ? _vm.filters.priceRange[1]
                    : _vm.filterParams.price.max},on:{"blur":_vm.checkMaxRangeInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkMaxRangeInput.apply(null, arguments)}}})])]),_c('div',[_c('slider',{attrs:{"value":_vm.filters.priceRange.length > 0
                  ? _vm.filters.priceRange
                  : [0, _vm.filterParams.price.max],"min":0,"max":_vm.filterParams.price.max},on:{"input":function (event) {
                  this$1.handleFiltersChange('priceRange', event);
                }}})],1)])],2),_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Availability ")])]),_c('template',{slot:'content'},[_c('radio-button-group',{attrs:{"name":"available","options":[
              { code: '', label: 'All' },
              { code: '1', label: 'Available' },
              { code: '0', label: 'Not Available' } ],"value":_vm.filters.available},on:{"input":function (event) {
                _vm.handleFiltersChange('available', event);
              }}})],1)],2)],1):_vm._e(),_c('main',{staticClass:"flex-1"},[(_vm.isBooksLoading)?_c('div',[_vm._v("Loading books...")]):_c('div',[(_vm.booksErrors)?_c('div',[_vm._v(" "+_vm._s(_vm.booksErrors)+" ")]):_c('div',{staticClass:"grid grid-cols-2 gap-3"},_vm._l((_vm.books),function(book){return _c('card-book',{key:book.id,attrs:{"bookData":book,"isShortlistPage":_vm.isShortlistPage},on:{"handle-filter-by-authors":function (event) {
                _vm.handleFiltersChange('authors', event);
              },"open-modal":_vm.openModal,"export-to-pdf":_vm.exportToPdf}})}),1),(!_vm.booksErrors && _vm.booksCount - _vm.books.length > 0)?_c('base-button',{staticClass:"mt-9",nativeOn:{"click":function($event){return _vm.$emit('handleFetchMore')}}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 1V11M11 6L1 6","stroke":"currentColor","stroke-width":"1.6","stroke-linecap":"round"}})]),_vm._v(" Show more "+_vm._s(_vm.booksCount - _vm.books.length)+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"vue-html2pdf"},[_c('transition',{attrs:{"name":"transition-anim"}},[(_vm.pdfFile)?_c('section',{staticClass:"pdf-preview"},[_c('button',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closePdf()}}},[_vm._v("×")]),_c('iframe',{attrs:{"src":_vm.pdfFile,"width":"100%","height":"100%"}})]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }