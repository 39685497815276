<template>
  <a
    :href="`/books/${bookData.handle}`"
    class="
      grid
      px-5
      pt-5
      pb-3
      rounded
      cursor-pointer
      grid-cols-card
      gap-x-6
      bg-grey-400
      hover:shadow-sm
    "
    @click.prevent="openModal(bookData.handle)"
  >
    <custom-image
      :src="bookData.imageUrl"
      imgClass="h-fit mb-3 shadow-md"
      :width="134"
    />
    <div class="mb-3">
      <h3 class="mb-2 text-base font-medium capitalize text-black-900">
        {{ bookData.title }}
      </h3>
      <h4
        v-for="(author, index) in bookData.author ? bookData.author : []"
        :key="index"
        class="inline mb-3 text-sm font-medium uppercase text-grey-700"
      >
        <span
          class="hover:underline"
          @click.stop.prevent="handleFilterByAuthor(author)"
          >{{ author }}</span
        ><span v-if="index + 1 < bookData.author.length">, </span>
      </h4>
      <div
        class="text-sm text-black-600 book-short-description"
        v-html="bookData.shortDescription"
      ></div>
    </div>
    <div class="mb-7">
      <h5 class="text-sm text-grey-700">Imprint:</h5>
      <p class="text-sm text-black-600">{{ bookData.imprint }}</p>
    </div>
    <div class="flex mb-7">
      <div class="flex-1">
        <h5 class="text-sm text-grey-700">ISBN:</h5>
        <p class="text-sm text-black-600">
          {{ bookData.isbn13 ? bookData.isbn13 : bookData.isbn10 }}
        </p>
      </div>
      <div>
        <h5 class="text-sm text-grey-700">Pub. Date:</h5>
        <p class="text-sm text-black-600">{{ bookData.publishingDate }}</p>
      </div>
    </div>
    <div
      class="flex items-center space-x-5"
      v-if="isAuthenticated && !isShortlistPage"
    >
      <export-pdf-button
        v-if="user.type === 'admin' || user.type === 'reseller'"
        @export-to-pdf="exportToPdf(bookData.id)"
      />
      <button-bookmark
        :added-to-bookmark="bookData.shortlistIds.length > 0"
        @bookmark-click="handleBookmark"
      />
    </div>
    <div v-else class="flex items-center space-x-5"></div>

    <div v-if="isAuthenticated" class="flex items-center justify-between">
      <span
        class="
          before:empty-content
          before:w-1.5
          before:h-1.5
          before:rounded-full
          before:mr-1.5
          text-base
          flex
          items-center
        "
        :class="[
          bookData.available
            ? 'before:bg-green-700 text-green-700'
            : 'before:bg-red-600 text-red-600',
        ]"
      >
        {{ isAvailableWord }}
      </span>

      <span
        v-if="bookData.price && bookData.currencyCode"
        class="text-xl font-medium uppercase text-black-900"
      >
        {{ bookData.currencyCode }} {{ bookData.price.toFixed(2) }}
      </span>
      <span v-else class="text-xl font-medium uppercase text-black-900">
        SGD -
      </span>
    </div>
  </a>
</template>

<script>
import { mapGetters } from "vuex";

import ExportPdfButton from "@/components/buttons/ButtonExportPdf.vue";
import ButtonBookmark from "@/components/buttons/ButtonBookmark.vue";
import CustomImage from "@/components/utils/CustomImage.vue";

import { LOAD_SELECTED_SHORTLIST } from "@/store/actions.type";

export default {
  name: "CardBook",
  components: {
    ExportPdfButton,
    ButtonBookmark,
    CustomImage,
  },
  props: {
    bookData: {
      type: Object,
      required: true,
    },
    isShortlistPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openModal(handle) {
      this.$emit("open-modal", handle);
    },
    async handleBookmark() {
      if (this.isAuthenticated) {
        await this.$store.dispatch(LOAD_SELECTED_SHORTLIST, {
          shortlistIds: [...this.bookData.shortlistIds],
          bookId: this.bookData.id,
          catalogId: null,
        });
        this.$modal.show("shortlist-modal", {
          component: "add-shortlist-modal",
        });
      } else {
        // redirect to login page if not yet authenticated
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.path },
        });
      }
    },
    exportToPdf(slug) {
      this.$emit("export-to-pdf", slug);
    },
    handleFilterByAuthor(value) {
      this.$emit("handle-filter-by-authors", [value]);
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    isAvailableWord() {
      return this.bookData.available ? "Available" : "Not available";
    },
  },
};
</script>
