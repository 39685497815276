<template>
  <div>
    <div class="flex items-start space-x-6">
      <aside
        v-if="isAuthenticated && !isShortlistPage"
        class="
          border border-grey-500
          rounded
          max-w-xs
          w-full
          divide-y divide-grey-500
        "
      >
        <accordion-item
          v-if="
            showPublishers &&
            filterParams.publishers &&
            filterParams.publishers.length > 0
          "
          class="relative px-4 py-3"
        >
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium">
              Publishers
            </span>
          </template>
          <template :slot="'content'">
            <checkbox-field
              name="publishers"
              :options="filterParams.publishers"
              :value="filters.publishers"
              @input="
                (event) => {
                  handleFiltersChange('publishers', event);
                }
              "
            />
            <!-- <select-input placeholder="Find Publishers" /> -->
          </template>
        </accordion-item>
        <accordion-item
          v-if="filterParams.imprints && filterParams.imprints.length > 0"
          class="relative px-4 py-3"
        >
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium"> Imprints </span>
          </template>
          <template :slot="'content'">
            <checkbox-field
              name="imprints"
              :options="filterParams.imprints"
              :value="filters.imprints"
              @input="
                (event) => {
                  handleFiltersChange('imprints', event);
                }
              "
            />
          </template>
        </accordion-item>
        <accordion-item
          v-if="filterParams.subjects && filterParams.subjects.length > 0"
          class="relative px-4 py-3"
        >
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium"> Subjects </span>
          </template>
          <template :slot="'content'">
            <checkbox-field
              name="subjects"
              :options="filterParams.subjects"
              :value="filters.subjects"
              @input="
                (event) => {
                  handleFiltersChange('subjects', event);
                }
              "
            />
          </template>
        </accordion-item>
        <accordion-item class="relative px-4 py-3">
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium">
              Publication Date
            </span>
          </template>
          <template :slot="'content'">
            <t-datepicker
              :monthsPerView="2"
              :multiple="true"
              :range="true"
              :weekStart="1"
              placeholder="Choose date or period"
              dateFormat="Y-m-d"
              userFormat="d M Y"
              :value="filters.publishingDate"
              @input="
                (event) => {
                  if (event && (event.length == 0 || event.length == 2)) {
                    handleFiltersChange('publishingDate', event);
                  }
                }
              "
            />
          </template>
        </accordion-item>
        <accordion-item class="relative px-4 py-3">
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium"> Price </span>
          </template>
          <template :slot="'content'">
            <div
              class="
                flex
                divide-x divide-grey-500
                border border-grey-500
                rounded
                overflow-hidden
                mb-5
              "
            >
              <div class="flex items-center px-3 py-2">
                <label
                  class="text-base text-black-900 mr-2"
                  for="rangeInputMin"
                >
                  SGD
                </label>
                <input
                  :value="
                    filters.priceRange.length > 0 ? filters.priceRange[0] : 0
                  "
                  id="rangeInputMin"
                  class="
                    w-full
                    text-base text-black-900
                    placeholder:text-grey-700
                    hide-arrows
                  "
                  type="number"
                  placeholder="From"
                  :min="0"
                  @blur="checkMinRangeInput"
                  @keydown.enter="checkMinRangeInput"
                />
              </div>
              <div class="flex items-center px-3 py-2">
                <label
                  class="text-base text-black-900 mr-2"
                  for="rangeInputMax"
                >
                  SGD
                </label>
                <input
                  :value="
                    filters.priceRange.length > 0
                      ? filters.priceRange[1]
                      : filterParams.price.max
                  "
                  id="rangeInputMax"
                  class="
                    w-full
                    text-base text-black-900
                    placeholder:text-grey-700
                    hide-arrows
                  "
                  type="number"
                  placeholder="To"
                  @blur="checkMaxRangeInput"
                  @keydown.enter="checkMaxRangeInput"
                />
              </div>
            </div>

            <div>
              <slider
                :value="
                  filters.priceRange.length > 0
                    ? filters.priceRange
                    : [0, filterParams.price.max]
                "
                :min="0"
                :max="filterParams.price.max"
                @input="
                  (event) => {
                    this.handleFiltersChange('priceRange', event);
                  }
                "
              />
            </div>

            <!-- <base-button>
              <svg
                class="mr-2"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.8L5.21053 9L11 1"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Apply
            </base-button> -->
          </template>
        </accordion-item>
        <accordion-item class="relative px-4 py-3">
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium">
              Availability
            </span>
          </template>
          <template :slot="'content'">
            <radio-button-group
              name="available"
              :options="[
                { code: '', label: 'All' },
                { code: '1', label: 'Available' },
                { code: '0', label: 'Not Available' },
              ]"
              :value="filters.available"
              @input="
                (event) => {
                  handleFiltersChange('available', event);
                }
              "
            />
          </template>
        </accordion-item>
      </aside>
      <main class="flex-1">
        <div v-if="isBooksLoading">Loading books...</div>
        <div v-else>
          <div v-if="booksErrors">
            {{ booksErrors }}
          </div>
          <div v-else class="grid grid-cols-2 gap-3">
            <card-book
              v-for="book in books"
              :key="book.id"
              :bookData="book"
              :isShortlistPage="isShortlistPage"
              @handle-filter-by-authors="
                (event) => {
                  handleFiltersChange('authors', event);
                }
              "
              @open-modal="openModal"
              @export-to-pdf="exportToPdf"
            ></card-book>
          </div>

          <base-button
            v-if="!booksErrors && booksCount - books.length > 0"
            class="mt-9"
            @click.native="$emit('handleFetchMore')"
          >
            <svg
              class="mr-2"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 1V11M11 6L1 6"
                stroke="currentColor"
                stroke-width="1.6"
                stroke-linecap="round"
              />
            </svg>
            Show more {{ booksCount - books.length }}
          </base-button>
        </div>
      </main>
    </div>

    <div class="vue-html2pdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfFile">
          <button @click.self="closePdf()">&times;</button>
          <iframe :src="pdfFile" width="100%" height="100%" />
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import "vue-slider-component/theme/default.css";
import { mapGetters } from "vuex";

import CardBook from "@/components/cards/CardBook.vue";
import { UPDATE_BOOK_BASE_PAGE } from "@/store/actions.type";
import { BookService } from "@/common/api.service";

export default {
  name: "TabBooks",
  components: {
    "card-book": CardBook,
    "base-button": () => import("@/components/utils/Button.vue"),
    "accordion-item": () => import("@/components/utils/AccordionItem.vue"),
    "checkbox-field": () => import("@/components/utils/CheckboxField.vue"),
    slider: () => import("@/components/utils/Slider.vue"),
    "radio-button-group": () =>
      import("@/components/utils/RadioButtonGroup.vue"),
  },
  props: {
    showPublishers: {
      type: Boolean,
      default: true,
    },
    isBooksLoading: Boolean,
    books: Array,
    booksCount: {
      type: Number,
      default: 0,
    },
    filterParams: {
      type: Object,
    },
    filters: {
      type: Object,
    },
    isShortlistPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pdfFile: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    booksErrors() {
      if (this.$route.query.q) {
        if (this.$route.query.q.trim().length < 3) {
          return "The search could not be completed because the keywords were too short.";
        }

        if (this.books.length === 0) {
          return "Sorry, No Books were found that matched your search query. Please try searching again.";
        }
      }
      return "";
    },
  },
  methods: {
    handleFiltersChange(key, value) {
      this.$emit("handleFilters", { key, value });
    },
    openModal(handle) {
      this.$store.dispatch(UPDATE_BOOK_BASE_PAGE, {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      });
      this.$router.push({ name: "book", params: { handle } });
    },
    async exportToPdf(slug) {
      const { data } = await BookService.getPdf(slug);
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    closePdf() {
      URL.revokeObjectURL(this.pdfFile);
      this.pdfFile = null;
    },
    checkMinRangeInput(event) {
      let newValue = +event.target.value;
      if (newValue < 0) {
        newValue = 0;
      }
      if (newValue > this.filterParams.price.max) {
        newValue = this.filterParams.price.max;
      }

      if (this.filters.priceRange.length > 0) {
        if (newValue > this.filters.priceRange[1]) {
          newValue = this.filters.priceRange[1];
        }
        this.handleFiltersChange("priceRange", [
          newValue,
          this.filters.priceRange[1],
        ]);
      } else {
        this.handleFiltersChange("priceRange", [
          newValue,
          this.filterParams.price.max,
        ]);
      }
    },
    checkMaxRangeInput(event) {
      let newValue = +event.target.value;
      if (newValue < 0) {
        newValue = 0;
      }
      if (newValue > this.filterParams.price.max) {
        newValue = this.filterParams.price.max;
      }

      if (this.filters.priceRange.length > 0) {
        if (newValue < this.filters.priceRange[0]) {
          newValue = this.filters.priceRange[0];
        }
        this.handleFiltersChange("priceRange", [
          this.filters.priceRange[0],
          newValue,
        ]);
      } else {
        this.handleFiltersChange("priceRange", [0, newValue]);
      }
    },
  },
};
</script>
